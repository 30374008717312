

import {  mapGetters } from "vuex";
import SpeedkitPicture from 'nuxt-speedkit/components/SpeedkitPicture';


 
export default {
  components: {
    SpeedkitPicture
  },
    computed: {
    ...mapGetters({
      media_match: "media_match",
    })
  },
  data() {
    return {
      carrusel_per_page: 3,
    }
  },
  async mounted() {
    this.$nextTick(() => {})


    if (this.media_match === 0) {
      //primera screen and (max-width: 600px)
      this.carrusel_per_page = 1;
    } else if (this.media_match === 1) {
      //segunda screen and (min-width: 601px) and (max-width: 767px)
      this.carrusel_per_page = 2;
    } else if (this.media_match === 2) {
      //tercera screen and (min-width: 768px) and (max-width: 991px)
      this.carrusel_per_page = 2;
    } else if (this.media_match === 3) {
      //cuarta screen and (min-width: 992px) and (max-width: 1199px)
      this.carrusel_per_page = 3;
    } else if (this.media_match === 4) {
      //quinta screen and (min-width: 1200px)
      this.carrusel_per_page = 3;
    }

  },
  watch: {
    media_match(newValue, oldValue) {
      if (newValue === 0) {
        //primera screen and (max-width: 600px)
        this.carrusel_per_page = 1;
      } else if (newValue === 1) {
        //segunda screen and (min-width: 601px) and (max-width: 767px)
        this.carrusel_per_page = 2;
      } else if (newValue === 2) {
        //tercera screen and (min-width: 768px) and (max-width: 991px)
        this.carrusel_per_page = 2;
      } else if (newValue === 3) {
        //cuarta screen and (min-width: 992px) and (max-width: 1199px)
        this.carrusel_per_page = 3;
      } else if (newValue === 4) {
        //quinta screen and (min-width: 1200px)
        this.carrusel_per_page = 3;
      }
    }

  }, 
  methods: {
    getPicture (imageUrl) {
      return {
        placeholders: [
          {
            format: 'jpg',
            url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg=='
          }
        ],
        sources: [
          {
            format: 'jpg',
            sizes: [
              // jpg
              { width: 410, height: 410,  url: imageUrl  }
            ]
          }
        ]
      }
    }
  },


}
